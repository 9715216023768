import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import settings from '../../../settings';


const logo = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/bmw-rider-academy-logo-01.svg`;
const bmwRiderAcademyLogo = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/bmw-motorrad-logo.svg`;
const today = new Date();
const showGiftCardBar = 
  today >= new Date(2023,10,24) && 
  today < new Date(2024,0,1);

class Nav extends React.Component {
  state = {
    menuIsOpen: true,
    menuToggle: false,
    isTop: true
  };

  handleMenuToggle = () => {
    this.setState((prev) => {
      return {
        menuIsOpen: !prev.menuIsOpen,
        menuToggle: !prev.menuToggle
      };
    });
  };

  render() {
    
    return (
      <>
        <header>
          <div className="mobile__nav">
            <div className="container">
              <Link to="/motorrad" className="mobile-logo" aria-label="moto-logo">
                <img src={logo} alt="Logo" />
              </Link>
              <div
                className={`hamburger ${this.state.menuIsOpen ? ' ' : 'is-open'}`}
                onClick={this.handleMenuToggle}
                onKeyDown={this.handleMenuToggle}
                role="button"
                tabIndex={0}
                aria-label="hamburger"
              >
                <div className="hamburger__toggle" />
              </div>
            </div>
          </div>
          <nav className={`nav ${this.state.menuToggle ? ' ' : 'hide-nav'}`}>
            <div className="logo-wrapper">
              <Link to="/motorrad" aria-label="motorrad-logo">
                <div className="logo">
                  <img src={logo} alt="Logo" />
                </div>
              </Link>
            </div>
            <ul className="navbar">
              <li className='width-full moto-nav'>
                <ul>
                  <li className='middle'>
                    <ul>
                      <li>
                        <Link to="/motorrad/schools" activeClassName="active">
                          Classes
                        </Link>
                      </li>
                      <li>
                        <Link to="/motorrad/experiences" activeClassName="active">
                          Experiences
                        </Link>
                      </li>
                      <li>
                        <Link to="/motorrad/touring" activeClassName="active">
                          Touring
                        </Link>
                      </li>
                      <li>
                        <Link to="/motorrad/privatetraining" activeClassName="active">
                          Private Training
                        </Link>
                      </li>
                      <li>
                        <Link to="/motorrad/locations" activeClassName="active">
                          Locations
                        </Link>
                      </li>
                      <li className='book-btn'>
                        <a className="nav-btn" href="https://pds.eventsbmw.com/" target="_blank" rel="noopener noreferrer">
                          <button>Book Now</button>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="bmw-logo">
                <Link to="/motorrad" aria-label="moto-logo">
                  <LazyLoadImage effect="blur" src={`${this.state.menuIsOpen ? bmwRiderAcademyLogo : logo}` } alt="BMW Performance Academy Logo" className="bmw-logo" />
                </Link>
              </li>
            </ul>
          </nav>
        </header>
        { showGiftCardBar ?
          <Link to="/motorrad/giftcards" aria-label="GiftCards" id="moto-gift-card-link">
            <div className="moto-gift-card-bar">
              GIVE THE GIFT OF SPEED - <span className='giftCardLink'>&nbsp;GIFT CARDS AVAILABLE</span>
            </div> 
          </Link> 
          : 
          ""
        }
      </>
    );
  }
}
export default Nav;
