import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';
import FlexCard from '../../components/motorrad/flexcard';
import GiftCardBackground from '../../components/giftcardbackground';
import settings from '../../../settings';


const topbanner = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/specialoffers/desktop/moto-specials-banner.jpg`;
const motospecialoffer = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/specialoffers/desktop/moto-special-offer.jpg`;
const SpecialOffers = () => {
  const getFirstCard = () => {
      return (
        <FlexCard
        rowType="row"
        image={motospecialoffer}
        imageAlt="Hold Your Handlebars"
        header="Hold Your Handlebars"
        subheader="There are no current special offers"
        btnStyle="btn-blue"
      >
        <p>Follow us on social media to make sure you stay up to date on the latest BMW U.S. Rider Academy special offers.
        </p>
        <Link href='https://www.instagram.com/bmwusrideracademy/' target="_blank">
          <button className="btn btn-bordered">Instagram</button>
        </Link><br/><br/>
        <Link href="https://www.facebook.com/bmwusrideracademy/" target="_blank">
          <button className="btn btn-bordered">Facebook</button>
        </Link>
      </FlexCard>
      )
  }
  return (
    <Layout>
      <SEO
        title="The BMW U.S. Rider Academy | Motorrad Tours and Classes"
        description="The BMW U.S. Rider Academy offers custom motorrad tours, riding classes – on and off-road, of course, and the chance to ride and try the latest BMW motorcycles."
      />
      <div className="giftcardpage">
          <GiftCardBackground
            imageDesktop={topbanner}
            imageMobile={topbanner}
            imageAlt="BMW Gift Card Top Banner"
          />
          <section className="drivingschool">
            <div className="container">
              <div className="giftcard__header">
                <h2>Special Offers</h2>
              </div>
            </div>
            {getFirstCard()}
          </section>
        </div>
    </Layout>
  );
};

export default SpecialOffers;